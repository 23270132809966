<template>
  <CrudTable
    v-bind="crudTableConfig"
    :storeModule="storeModule"
    :showAddItemsCustom="showAddItems"
    :addItems="showAddItems"
    @addItemsEvent="onCreateCampaign"
  >

    <template v-slot:[getLinkAttributes.path]="{ item }">
      <router-link :to="{ name: 'CampaignDetail', params: { id: item.id }}" :target="openLinkNewTab">
        {{ Lget(item, getLinkAttributes.name, '-') || '-'}}
      </router-link>
    </template>

  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { RESPONSE_LEVEL, ENVIRONMENT } from '@/variables'
import { mapActions, mapState } from 'vuex'
import Lget from 'lodash/get'

export default {
  name: 'CampaignTable',
  components: { CrudTable },
  data () {
    return {
      storeModule: 'campaigns'
    }
  },

  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),

    getLinkAttributes () {
      const linkAttributes = {}
      const value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''
      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    },
    showAddItems () {
      return ENVIRONMENT !== 'CAC'
    },
    title () {
      return this.$t('campaigns.sectionName')
    },
    openLinkNewTab () {
      return this.crudTableConfig.openInNewTab ? '_blank' : '_self'
    }
  },

  methods: {
    ...mapActions('campaigns', ['reloadItemList', 'createCampaign']),
    ...mapActions({ addNotification: 'addNotification', dismissNotifications: 'dismissNotifications' }),
    Lget: Lget,
    onCreateCampaign () {
      this.createCampaign()
        .then(response => {
          if (response.level === RESPONSE_LEVEL.SUCCESS) {
            this.$router.push({
              name: 'CampaignDetail',
              params: { id: response.id }
            })
          }
        })
    }
  },
  beforeDestroy () {
    this.dismissNotifications()
  }
}
</script>
