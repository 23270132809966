<template>
  <Layout :top-bar-title="$t('campaignMassiveCreation.sectionName')" :sections="[]"
          baseLayoutInfoUrl="/base-layout-info/">
    <CampaignMassiveCreationTable @upload-file="onOpenDialog"/>
    <v-dialog v-model="showDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="mb-6">{{ $t('campaignMassiveCreation.selectFiles') }}</v-card-title>
        <v-card-actions class="d-flex flex-column flex-grow-1 flex-shrink-1 px-6 pt-0 pb-5">
          <v-file-input v-model="files" class="mb-6 align-self-start width-100 override-file-size"
                        chips show-size counter multiple dense autofocus outlined hide-details="auto"
                        truncate-length="52" accept=".xlsx" :placeholder="$t('campaignMassiveCreation.clickToAdd')"
                        :label="$t('campaignMassiveCreation.files')">
            <template v-slot:selection="{text, index}">
              <v-chip label color="primary" class="truncate-text" close @click:close="onRemoveSelectedFile(index)">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-sheet class="d-flex flex-grow-1 flex-shrink-1 align-self-end">
            <v-btn text class="mr-2" @click="showDialog = false">{{ $t('general.cancel') }}</v-btn>
            <v-btn color="primary" :disabled="acceptDisabled" @click="onUploadFilesDebounced">
              {{ $t('general.accept') }}
            </v-btn>
          </v-sheet>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Layout>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import Layout from "@/apps/core/components/Layout.vue";
import CampaignMassiveCreationTable from '../../components/tables/CampaignMassiveCreationTable.vue'
import CampaignTable from "@/apps/dido/components/tables/CampaignTable.vue";
import {createSuccessNotification} from "@/lib/unnotificationsqueue";
import i18n from "@/i18n";
import debounce from "lodash/debounce";


@Component({components: {CampaignTable, Layout, CampaignMassiveCreationTable}})
export default class CampaignMassiveCreation extends Vue {

  showDialog = false;

  files = [];

  get acceptDisabled() {
    return this.files.length === 0;
  }

  onOpenDialog() {
    this.showDialog = true;
  }

  onRemoveSelectedFile(fileIndex) {
    this.files.splice(fileIndex, 1)
  }

  onUploadFilesDebounced = debounce(this.onUploadFiles, 500)

  async onUploadFiles() {
    const erroredFiles = await this.$store.dispatch('campaignMassiveCreation/createCampaigns', this.files);
    if (!erroredFiles.length) {
      this.$store.dispatch('addNotification', createSuccessNotification(i18n.tc('campaignMassiveCreation.createCampaignsSuccess')))
      this.showDialog = false
      this.files = []
      this.files = []
    }
    location.reload();
  }
}
</script>

<style scoped lang="scss">
.width-100 {
  width: 100%;
}

.override-file-size {
  > :nth-child(2) {
    > :nth-child(2) {
      margin-top: 5px;
    }
  }
}
</style>
