<template>
  <CrudTable
      ref="crudTable"
      v-bind="crudTableConfig"
      storeModule="campaignMassiveCreation"
      :show-add-items-custom="true"
      :hide-pagination="false"
      :show-search-panel="false"
      :show-actions-panel="false"
      :active-single-select="false"
      :show-select-panel="false"
      @addItemsEvent="$emit('upload-file')">
    <template v-slot:[getLinkAttributes.path]="{ item }">
      <a :href="item.log_url" :target="openLinkNewTab">
        {{ Lget(item, getLinkAttributes.name, '-') }}
      </a>
    </template>
    <template v-slot:item.creation_datetime="{ item }">
      {{ formatDate(Lget(item, 'creation_datetime', null)) }}
    </template>
    <template v-slot:item.eta_in_seconds="{ item }">
      {{ formatSeconds(Lget(item, 'eta_in_seconds', null)) }}
    </template>
  </CrudTable>
</template>

<script>
import i18n from '@/i18n'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapActions, mapState } from 'vuex'
import Lget from 'lodash/get'

export default {
  name: 'CampaignMassiveCreationTable',
  components: { CrudTable },
  computed: {
    ...mapState({
      crudTableConfig: function (state) {
        return state['campaignMassiveCreation'].crudTableConfig
      }
    }),
    getLinkAttributes () {
      const value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''
      return { path: `item.${value}`, name: value }
    },
    openLinkNewTab () {
      return this.crudTableConfig.openInNewTab ? '_blank' : '_self'
    }
  },
  methods: {
    ...mapActions({ dismissNotifications: 'dismissNotifications' }),
    Lget,
    formatDate (date) {
      let result = '-'
      if (date) {
        const locale = i18n.locale === 'en' ? 'en-GB' : i18n.locale
        result = new Intl.DateTimeFormat(locale, {
          dateStyle: 'long',
          timeStyle: 'short'
        }).format(new Date(date))
      }
      return result
    },
    formatSeconds (rawSeconds) {
      let time = '-'
      if (rawSeconds !== null) {
        const hours = (Math.floor(rawSeconds / 3600))
        const minutes = (Math.floor(rawSeconds / 60) % 60)
        const seconds = (Math.round(rawSeconds % 60))

        time = ''
        if (hours) time += this.$tc('campaignMassiveCreation.hours', hours)
        if (minutes) time += ` ${this.$tc('campaignMassiveCreation.minutes', minutes)}`
        if (!time && seconds !== 0) time += ` ${this.$tc('campaignMassiveCreation.seconds', seconds)}`
        time = time.trim()
      }
      return time
    }
  },
  beforeDestroy () {
    this.dismissNotifications()
  }
}
</script>
<style scoped lang="sass">
::v-deep .v-data-table__wrapper
  tr
    height: 37px
</style>
